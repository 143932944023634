<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Configurações</h5>
						</div>
					</div>

					<div class="row">
                        <div class="col-lg-6">
                            <div class="row form-group">
                                <label class="col-xl-5 col-lg-8 col-md-6 col-form-label">Vencimento padrão de orçamentos*</label>
                                <input
                                    :value="_.get(custom_due_budgets,'value')"
                                    @change="custom_due_budgets.value = $event.target.value"
                                    class="form-control col-xl-1 col-lg-2 col-md-1"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row form-group">
                                <label class="col-xl-5 col-lg-8 col-md-6 col-form-label">Vencimento padrão de pedidos financiados*</label>
                                <input
                                    :value="_.get(custom_due_financed_orders,'value')"
                                    @change="custom_due_financed_orders.value = $event.target.value"
                                    class="form-control col-xl-1 col-lg-2 col-md-1"
                                />
                            </div>
                        </div>
                    </div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
    //Mixins
    import FetchingMixin from "@/mixins/FetchingMixin";
    import Swal from "sweetalert2";
    import {exibeErro, sanitize} from "@/helpers";

    //Aux
    import EjButton from "@/components/EjButton";
    import {vueSet} from "vue-deepset";
    // const _ = require('lodash');

    export default {
        name: 'SettingsEdit',

        data() {
            return {
                loadingSettings: false,
                saving: false,

                settings: [],
            }
        },

        computed: {
            spinner() {
                return this.loadingSettings;
            },

            custom_due_budgets: {
                get() {
                    return this.settings.find((setting) => setting.key === "custom_due_budgets");
                },

                set(value) {
                    const index = this.settings.findIndex((setting) => setting.key === "custom_due_budgets");
                    this.settings[index] = value;
                }
            },

            custom_due_financed_orders: {
                get() {
                    return this.settings.find((setting) => setting.key === "custom_due_financed_orders");
                },

                set(value) {
                    const index = this.settings.findIndex((setting) => setting.key === "custom_due_financed_orders");
                    this.settings[index] = value;
                }
            },

            // order_signature: {
            //     get() {
            //         const setting = this.settings.find((setting) => setting.key === "order_signature");
            //         if (setting)
            //             setting.value = JSON.parse(setting.value);
            //         return setting;
            //     },
            //
            //     set(value) {
            //         const index = this.settings.indexOf((setting) => setting.key === "order_signature");
            //         if (index)
            //             this.settings[index].value = value;
            //     }
            // },
        },

        mixins: [
            FetchingMixin
        ],

        methods: {
            async salvar() {
                const vm = this;

                vm.saving = true;

                const p1 = vm.$store.dispatch(`settings/patch`, {
                    id: vm.custom_due_budgets.id,
                    data: sanitize(vm.custom_due_budgets)
                });

                const p2 = vm.$store.dispatch(`settings/patch`, {
                    id: vm.custom_due_financed_orders.id,
                    data: sanitize(vm.custom_due_financed_orders)
                });

                try {
                    await Promise.all([p1, p2]);

                    Swal.fire(
                        'Sucesso!',
                        'Configurações atualizadas com sucesso!',
                        'success'
                    );

                } catch (error) {
                    // handle error
                    exibeErro(error);
                }

                // always executed
                vm.saving = false;
            }
        },

        mounted() {
            const vm = this;

            this.loadingSettings = true;
            this.$store.dispatch(`settings/index`)
                .then(function (response) {
                    vueSet(vm, 'settings', response.data.data);
                })
                .catch(function (error) {
                    // handle error
                    exibeErro(error);
                })
                .then(function () {
                    // always executed
                    vm.loadingSettings = false;
                });
        },

        components: {
            EjButton
        },
    }
</script>

<style scoped>
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        .content {
            padding: 10px 20px 20px 20px !important;
        }
    }
</style>
